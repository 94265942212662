var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DK,FK,HK,KK,NK;$CLJS.AK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.nF)};$CLJS.BK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.CK=new $CLJS.M(null,"column-ref","column-ref",2018188376);DK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.EK=new $CLJS.M(null,"operators","operators",-2064102509);
FK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.GK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);HK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.IK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.JK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
KK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.LK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.MK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);NK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.OK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.PK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(FK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.aG],null)],null)],null));$CLJS.Y(NK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,FK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null));$CLJS.Y(HK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.jF],null),NK,$CLJS.pD],null));
$CLJS.Y(KK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.jF],null),FK,$CLJS.iK],null));
$CLJS.IF.g($CLJS.jF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.jF],null),FK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.iK,$CLJS.pD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.zi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,KK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,HK],null)],null)],null));$CLJS.pE($CLJS.jF,$CLJS.nF);$CLJS.KD.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.wE($CLJS.qA,$CLJS.H([$CLJS.pD]));$CLJS.KD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});
$CLJS.pE($CLJS.qA,$CLJS.nF);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.pD],null)],null)],null));
$CLJS.IF.g($CLJS.ZE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.ZE],null),DK,$CLJS.zj],null));$CLJS.KD.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.pE($CLJS.ZE,$CLJS.nF);$CLJS.wE($CLJS.HE,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null)]));$CLJS.pE($CLJS.HE,$CLJS.nF);
$CLJS.wE($CLJS.eF,$CLJS.H([$CLJS.Zr,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)]));$CLJS.pE($CLJS.eF,$CLJS.nF);
$CLJS.Y($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.MD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.Hk.g($CLJS.q($CLJS.gB),$CLJS.nF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.AK(a)}],null)],null));
export const MODERATION_STATUS = {
  verified: "verified",
};

export const MODERATION_STATUS_ICONS = {
  verified: {
    name: "verified",
    color: "brand",
  },
  verified_filled: {
    name: "verified_filled",
    color: "brand",
  },
  null: {
    name: "close",
    color: "text-light",
  },
};

var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var eU,fU,gU,iU,jU,kU,lU,mU,nU,oU;eU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);fU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);gU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.hU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);iU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);jU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);kU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
lU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);mU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);nU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);oU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(eU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.$T)));$CLJS.Y(iU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,$CLJS.IL,$CLJS.xM,$CLJS.CE,$CLJS.Ps,$CLJS.Mj,$CLJS.DO],null));
$CLJS.Y(lU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.pD,$CLJS.Bj],null)],null)],null));
$CLJS.Y(mU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null)],null));
$CLJS.Y(jU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Ar,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null)],null)],null));
$CLJS.Y(kU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.IL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.hK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.XJ],null)],null)],null));
$CLJS.Y(oU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.xM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,$CLJS.WJ],null)],null)],null));$CLJS.Y(nU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.OT));
$CLJS.Y(fU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null)],null)],null)],null));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Vi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null)],null)],null)],null));
$CLJS.Y($CLJS.hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.pD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));
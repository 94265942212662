var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var PH,RH,TH,bI,eI,jI;$CLJS.OH=new $CLJS.M(null,"get-month","get-month",-369374731);PH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.QH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);RH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.SH=new $CLJS.M(null,"iso","iso",-1366207543);TH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.UH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.VH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.WH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.XH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.YH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.ZH=new $CLJS.M(null,"us","us",746429226);$CLJS.$H=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.aI=new $CLJS.M(null,"now","now",-1650525531);
bI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.cI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.dI=new $CLJS.M(null,"get-day","get-day",127568857);eI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.fI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.gI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.hI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.iI=new $CLJS.M(null,"get-hour","get-hour",622714059);jI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.kI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.lI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.mI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.nI=new $CLJS.M(null,"target","target",253001721);$CLJS.wE($CLJS.ZA,$CLJS.H([$CLJS.Zr,$CLJS.aC,$CLJS.ji,$CLJS.cD]));$CLJS.KD.m(null,$CLJS.XH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.LD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Jk,null,$CLJS.Lk,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.pE($CLJS.XH,$CLJS.bE);
for(var oI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,$CLJS.eB],null)),pI=null,qI=0,rI=0;;)if(rI<qI){var sI=pI.X(null,rI);$CLJS.wE(sI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.pE(sI,$CLJS.XH);rI+=1}else{var tI=$CLJS.y(oI);if(tI){var uI=tI;if($CLJS.vd(uI)){var vI=$CLJS.ic(uI),xia=$CLJS.jc(uI),yia=vI,zia=$CLJS.D(vI);oI=xia;pI=yia;qI=zia}else{var wI=$CLJS.z(uI);$CLJS.wE(wI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UD],null),$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.pE(wI,$CLJS.XH);oI=$CLJS.B(uI);pI=null;qI=0}rI=0}else break}
for(var xI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VH,$CLJS.OH,$CLJS.dI,$CLJS.iI,$CLJS.fI,$CLJS.UH,$CLJS.kI],null)),yI=null,zI=0,AI=0;;)if(AI<zI){var Aia=yI.X(null,AI);$CLJS.wE(Aia,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));AI+=1}else{var BI=$CLJS.y(xI);if(BI){var CI=BI;if($CLJS.vd(CI)){var DI=$CLJS.ic(CI),Bia=$CLJS.jc(CI),Cia=DI,Dia=$CLJS.D(DI);xI=Bia;yI=Cia;zI=Dia}else{var Eia=$CLJS.z(CI);$CLJS.wE(Eia,$CLJS.H([$CLJS.Zr,
$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));xI=$CLJS.B(CI);yI=null;zI=0}AI=0}else break}$CLJS.wE($CLJS.YA,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)]));
for(var EI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.kI,null,$CLJS.fI,null,$CLJS.iI,null,$CLJS.gI,null,$CLJS.UH,null,$CLJS.OH,null,$CLJS.VH,null,$CLJS.dI,null],null),null)),FI=null,GI=0,HI=0;;)if(HI<GI){var Fia=FI.X(null,HI);$CLJS.wE(Fia,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));HI+=1}else{var II=$CLJS.y(EI);if(II){var JI=II;if($CLJS.vd(JI)){var KI=$CLJS.ic(JI),Gia=$CLJS.jc(JI),Hia=KI,Iia=$CLJS.D(KI);
EI=Gia;FI=Hia;GI=Iia}else{var Jia=$CLJS.z(JI);$CLJS.wE(Jia,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));EI=$CLJS.B(JI);FI=null;GI=0}HI=0}else break}$CLJS.Y(jI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.SH,$CLJS.ZH,$CLJS.cI],null));
$CLJS.yE($CLJS.aB,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.hI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.pD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ts,"valid timezone ID",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.hj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.NH.tz.names())),$CLJS.EH],null)],null));
$CLJS.yE($CLJS.QH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hI],null)],null)],null)],
null)]));$CLJS.pE($CLJS.QH,$CLJS.XH);$CLJS.wE($CLJS.aI,$CLJS.H([$CLJS.Zr,$CLJS.Kk]));$CLJS.Y(TH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,$CLJS.Lk],null))}],null)],null));
$CLJS.Y(PH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TH],null)],null)],null)],null));
$CLJS.IF.g($CLJS.WH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.ts,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.WH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UF],null)],null)],null)],null)],null)],null));
$CLJS.KD.m(null,$CLJS.WH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Lk:$CLJS.n($CLJS.oE($CLJS.ZF,a))?$CLJS.Jk:$CLJS.Lk:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.yH,b))?$CLJS.Jk:$CLJS.n($CLJS.dh($CLJS.zH,b))?$CLJS.Jk:null:null;if($CLJS.n(a))return a;b=$CLJS.LD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.xj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(eI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Qu],null),$CLJS.ji],null));
$CLJS.yE($CLJS.$A,$CLJS.H([$CLJS.Zr,$CLJS.Lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ei,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null)],null)],null)]));
$CLJS.wE($CLJS.Fx,$CLJS.H([$CLJS.Zr,$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YF],null)]));$CLJS.Y(RH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.dr,$CLJS.WF,$CLJS.mi,$CLJS.Ij,$CLJS.YH,$CLJS.lI,$CLJS.mI,$CLJS.lj,$CLJS.ri,$CLJS.hi,$CLJS.Ti,$CLJS.TF],null));$CLJS.Y(bI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.SH,$CLJS.ZH,$CLJS.cI],null));
$CLJS.yE($CLJS.cB,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ei,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bI],null)],null)],
null)],null)]));
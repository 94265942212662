var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var tV,Kma,Lma,BV,Mma,Nma,Pma,xV,Qma,Oma;tV=function(a){return a+1};$CLJS.uV=function(a){if("string"===typeof a){var b=$CLJS.dh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Ll(a));};
Kma=function(a){var b=new $CLJS.Ca,c=$CLJS.ut;$CLJS.ut=new $CLJS.pc(b);try{var d=$CLJS.ut,e=$CLJS.Ra($CLJS.Cu(d)),f=$CLJS.ut;$CLJS.ut=e?$CLJS.tu(d):d;try{d=$CLJS.Eu;$CLJS.Eu=!0;try{$CLJS.Gu(a)}finally{$CLJS.Eu=d}$CLJS.E.g(0,$CLJS.Bt($CLJS.ut,$CLJS.Dt))||$CLJS.Yb($CLJS.ut,"\n");$CLJS.At()}finally{$CLJS.ut=f}$CLJS.kh($CLJS.p.h(b))}finally{$CLJS.ut=c}};$CLJS.vV=function(a,b){return $CLJS.Ne.j($CLJS.ha,a,b)};
$CLJS.wV=function(a){var b=$CLJS.qu;$CLJS.qu=120;try{var c=new $CLJS.Ca,d=$CLJS.La,e=$CLJS.Na;$CLJS.La=!0;$CLJS.Na=function(f){return c.append(f)};try{Kma(a)}finally{$CLJS.Na=e,$CLJS.La=d}return $CLJS.p.h(c)}finally{$CLJS.qu=b}};
Lma=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.FU,$CLJS.Pd),c=$CLJS.J.j(a,$CLJS.CU,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Se($CLJS.N),e=$CLJS.Se($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.zh.v(e,$CLJS.ok,u,$CLJS.QC(tV,0)),u);if($CLJS.E.g(x,1))return $CLJS.zh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==xV&&(xV=$CLJS.Se(0));var v=$CLJS.qh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.zh.g(xV,tV))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.yV=function(a){var b=$CLJS.ud(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.sd(a)&&$CLJS.Cd(a,$CLJS.xD)):b:b};$CLJS.zV=function(a){return $CLJS.yV(a)?$CLJS.oD.h($CLJS.ED(a)):null};$CLJS.AV=function(a,b){return $CLJS.GD($CLJS.yV(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,new $CLJS.h(null,2,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.qi,$CLJS.LD(a)],null),a],null),function(c){return $CLJS.ek.l($CLJS.R.j(c,$CLJS.oD,b),$CLJS.U,$CLJS.H([$CLJS.kD]))})};
BV=function(a,b,c){var d=$CLJS.Cd(a,b)?$CLJS.ok.j(a,b,function(e){return $CLJS.ud(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.nr)?$CLJS.yf($CLJS.Xe(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Cd(a,b)?$CLJS.AU(d,$CLJS.Ae([b,c])):d};Mma=function(a){var b=$CLJS.Fl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gO,$CLJS.JP],null));b=CV.h?CV.h(b):CV.call(null,b);return $CLJS.qV($CLJS.R.l(BV($CLJS.ek.l(a,$CLJS.gO,$CLJS.H([$CLJS.JP])),$CLJS.RQ,$CLJS.vK),$CLJS.Vs,$CLJS.nK,$CLJS.H([$CLJS.sK,b])))};
Nma=function(a){return $CLJS.nk.g(Mma,a)};Pma=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.ok.j($CLJS.ml(a)?new $CLJS.h(null,1,[$CLJS.DV,a],null):a,$CLJS.DV,function(b){return $CLJS.nk.g(function(c){return $CLJS.R.j($CLJS.Ez(c,$CLJS.eA),$CLJS.Vs,$CLJS.KJ)},b)}),$CLJS.Vs,Oma):null};
$CLJS.EV=function(a){if($CLJS.E.g($CLJS.Vs.h(a),$CLJS.iV))return a;var b=$CLJS.Vi.h(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "native":return $CLJS.Dk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.iV,$CLJS.sK,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Vs,$CLJS.cV],null),$CLJS.AU($CLJS.kP.h(a),new $CLJS.h(null,1,[$CLJS.FE,$CLJS.kP],null))]))],null)],null),$CLJS.ek.l(a,$CLJS.Vi,$CLJS.H([$CLJS.kP]))]));case "query":return $CLJS.Dk.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Vs,$CLJS.iV,$CLJS.sK,CV($CLJS.FE.h(a))],null),$CLJS.ek.l(a,$CLJS.Vi,$CLJS.H([$CLJS.FE]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.FV=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.sK);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.Uh($CLJS.fD("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Qma,$CLJS.D(a)],null));return c};
$CLJS.GV=function(a,b){a=$CLJS.EV(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.sK);return $CLJS.J.g($CLJS.yf(c),$CLJS.FV(a,b))};$CLJS.HV=function(a){return"string"===typeof a&&(a=$CLJS.eh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.uV(a)):null};$CLJS.JV=function(){return $CLJS.lk.g(IV,Lma($CLJS.H([$CLJS.FU,$CLJS.aA,$CLJS.CU,function(a,b){return IV.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};xV=null;
$CLJS.KV=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.LV=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.MV=new $CLJS.M(null,"join","join",-758861890);$CLJS.NV=new $CLJS.M(null,"stage","stage",1843544772);Qma=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.DV=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.OV=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Oma=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.PV=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var Rma,Sma,Tma,Uma,Vma,CV,IV;Rma=$CLJS.Se($CLJS.N);Sma=$CLJS.Se($CLJS.N);Tma=$CLJS.Se($CLJS.N);Uma=$CLJS.Se($CLJS.N);Vma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.gB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.QV=new $CLJS.Ph($CLJS.qh.g("metabase.lib.util","custom-name-method"),$CLJS.XA,Vma,Rma,Sma,Tma,Uma);$CLJS.QV.m(null,$CLJS.Oh,function(a){return $CLJS.yV(a)?$CLJS.zz($CLJS.kD,$CLJS.oD)($CLJS.ED(a)):null});
CV=function CV(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.JP),d=$CLJS.J.g(a,$CLJS.TN);c=$CLJS.n(c)?CV.h?CV.h(c):CV.call(null,c):$CLJS.xf;d=Pma(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.YK(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.pU],null),d):c;d=$CLJS.n($CLJS.kP.h(a))?$CLJS.cV:$CLJS.WU;a=$CLJS.Dk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Vs,d],null),$CLJS.ek.l(a,$CLJS.JP,$CLJS.H([$CLJS.TN]))]));a=$CLJS.y($CLJS.FM.h(a))?$CLJS.ok.j(a,$CLJS.FM,Nma):a;a=BV(a,$CLJS.NE,$CLJS.jV);return $CLJS.Yd.g(c,
a)};
$CLJS.RV=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.EV(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.sK);d=$CLJS.FV(c,d);e=$CLJS.Ne.M($CLJS.ok,$CLJS.yf(k),d,e,f);return $CLJS.R.j(c,$CLJS.sK,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=
b;return a}();
IV=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.oV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.Wma=RegExp(" id$","i");
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var m5,Fsa,Gsa,Hsa,o5,r5,s5,t5,Isa,Jsa,w5,u5,v5,z5,A5,Ksa,Lsa,B5,Msa,Nsa,Osa,Psa,Qsa,Rsa,Ssa,D5,Tsa,Usa,Vsa,E5,Wsa,Xsa,F5,Ysa,Zsa,$sa,ata;m5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=m5($CLJS.J.g(a,b),c),$CLJS.od(c)?$CLJS.ek.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.ek.g(a,b)}return a};
Fsa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Gl(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Cb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ne.j(l,$CLJS.Cb(v),m)):f}(a,b,c,d)};Gsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Fsa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.n5=function(a){return $CLJS.xD.h($CLJS.ED(a))};
Hsa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.aP,$CLJS.z(b))?$CLJS.AV(d,function(){var f=$CLJS.QV.h(d);return $CLJS.n(f)?f:$CLJS.zV(c)}()):d;return Gsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.E.g($CLJS.n5(C),$CLJS.n5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):
$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.E.g($CLJS.n5(x),$CLJS.n5(c))?e:x,m($CLJS.Hc(t)))}return null}},null,null)}(f)}())})};
o5=function(a,b,c,d){var e=$CLJS.Bz(a,b);if($CLJS.n(e)){var f=$CLJS.n5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.ed(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.mk.h($CLJS.lk.g($CLJS.Tg([f]),$CLJS.n5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RD],null))&&$CLJS.Le(function(l){return $CLJS.G1(l,$CLJS.qA)},e)?null:e;if($CLJS.y(e))return $CLJS.YK(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,$CLJS.vK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.Uh($CLJS.ID("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Cx,$CLJS.LV,$CLJS.vK,$CLJS.Bz(a,b),$CLJS.MV,$CLJS.Bz(a,$CLJS.nd(b)),$CLJS.GK,d,$CLJS.NV,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,$CLJS.RD],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.xW.v(a,$CLJS.nd(b),$CLJS.ek,k):m5(a,b)}return a};$CLJS.p5=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.sK);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.q5=function(a,b){a=$CLJS.RV.l(a,b,$CLJS.ek,$CLJS.H([$CLJS.RD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.mk.h($CLJS.lk.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.IY,null,$CLJS.HY,null],null),null),$CLJS.MJ)),$CLJS.V_.j(a,b,$CLJS.GV(a,b)))};
r5=function(a,b,c,d){var e=$CLJS.P1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.qG(function(f){return $CLJS.T1.v(a,b,f,d)},c),(e=!$CLJS.Cd(c,null))?(e=$CLJS.P1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Le(function(f){return $CLJS.P1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
s5=function(a,b){var c=$CLJS.C3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.YE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RD],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aP],null)],null),function(){return function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.vd(l)){var m=
$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.F(c,x);$CLJS.RD.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,x,$CLJS.RD],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.RD.h(m)instanceof $CLJS.M)l=$CLJS.Hc(l);else return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,u,$CLJS.RD],null),k($CLJS.Hc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,x,$CLJS.vK],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,u,$CLJS.vK],null),k($CLJS.Hc(l)))}return null}},null,null)}(d)}()]))};
t5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.pF),k=$CLJS.J.g(e,$CLJS.vQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.rG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.pF.h($CLJS.dd(m)),f)&&$CLJS.E.g($CLJS.vQ.h($CLJS.dd(m)),k)&&$CLJS.E.g($CLJS.ed(m),l)},$CLJS.El($CLJS.xr,$CLJS.PQ.h($CLJS.GV(a,b)))))};
Isa=function(a,b,c,d){c=t5(a,b,c);return $CLJS.n(c)?$CLJS.RV.l(a,b,$CLJS.xW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.PQ,c,2,1],null),$CLJS.lk.g(function(e){return $CLJS.U0($CLJS.al,e)},$CLJS.Dk),d])):a};Jsa=function(a,b,c){c=t5(a,b,c);return $CLJS.n(c)?$CLJS.RV.l(a,b,o5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PQ],null),$CLJS.Bz($CLJS.GV(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PQ,c],null)),b])):a};
w5=function(a,b,c,d,e,f){var k=$CLJS.RV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.n5(e);return $CLJS.kk.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(m,t){try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.aP))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.zV(e);K=u5.W?u5.W(k,b,c,$CLJS.qA,$CLJS.N,K):u5.call(null,k,b,c,$CLJS.qA,$CLJS.N,K);return v5.v?v5.v(K,b,c,l):v5.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.ZE))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=u5.W?u5.W(k,b,c,$CLJS.ZE,$CLJS.N,l):u5.call(null,k,b,c,$CLJS.ZE,$CLJS.N,l);return v5.v?v5.v(S,b,c,l):v5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.YE))return new $CLJS.P(null,
1,5,$CLJS.Q,[v5.v?v5.v(k,b,c,l):v5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.RD))return new $CLJS.P(null,1,5,$CLJS.Q,[v5.v?v5.v(k,b,c,l):v5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.ud(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ce(C,$CLJS.FM))try{var G=$CLJS.F(t,2);if($CLJS.ce(G,$CLJS.RD))return new $CLJS.P(null,1,5,$CLJS.Q,[v5.v?v5.v(k,b,c,l):v5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw X;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.xf,d)))):k};
u5=function(a,b,c,d,e,f){var k=$CLJS.GV(a,b),l=$CLJS.df(function(t){var u=$CLJS.Bz(k,t);return $CLJS.n(u)?$CLJS.Dl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function G(A,C){try{if($CLJS.ud(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.od(e)||$CLJS.T0($CLJS.Wg(e),$CLJS.Wg(S)))try{var X=$CLJS.F(C,2);if($CLJS.E.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Z;}catch(da){if(da instanceof Error){var T=
da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)return $CLJS.BU(G,A,C);throw T;}throw da;}}($CLJS.xf,v))))},u):null},$CLJS.H([s5(a,b)])),m=$CLJS.Ve($CLJS.ac($CLJS.xf));a=$CLJS.cb(function(t,u){var v=$CLJS.I(u,0,
null);u=$CLJS.I(u,1,null);return w5(t,b,c,v,u,function(x,A,C){try{return o5(x,A,C,b)}catch(K){if(K instanceof Error){A=K;var G=$CLJS.O($CLJS.Jl(A));C=$CLJS.J.g(G,$CLJS.Cx);G=$CLJS.J.g(G,$CLJS.MV);if($CLJS.E.g(C,$CLJS.LV))return m.nd(null,$CLJS.te(m.Lb(null),G)),x;throw A;}throw K;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.x5.j?$CLJS.x5.j(t,b,u):$CLJS.x5.call(null,t,b,u)},a,$CLJS.cc($CLJS.q(m)))};
v5=function(a,b,c,d){b=$CLJS.p5(c,b);if($CLJS.n(b)){var e=$CLJS.GV(c,b);c=$CLJS.Me(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.MJ),l=$CLJS.J.g(f,$CLJS.C0);return $CLJS.E.g($CLJS.xY,k)&&$CLJS.E.g(d,l)?$CLJS.LY.h(f):null},$CLJS.X_.j(c,b,e));return $CLJS.n(c)?u5(a,b,a,$CLJS.jF,$CLJS.N,c):a}return a};
z5=function(a,b,c,d,e){var f=$CLJS.sV.h(c),k=$CLJS.GV(a,b);c=$CLJS.rG(function(t){var u=$CLJS.Bz(k,t);if($CLJS.n(u)){var v=$CLJS.n5(f);return $CLJS.n($CLJS.Me($CLJS.lk.j($CLJS.Tg([v]),$CLJS.xD,$CLJS.dd),u))?t:null}return null},s5(a,b));var l=(d=$CLJS.E.g($CLJS.GF,d))?$CLJS.sV.h(e):null;e=d?function(t,u,v){return Hsa(t,u,v,l)}:function(t,u,v){return o5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.YE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.ed(f),$CLJS.ed(l))?
Isa(a,b,f,$CLJS.Fl($CLJS.dd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vQ,$CLJS.pF],null))):m?Jsa(a,b,f):a;return $CLJS.n(c)?(a=w5(a,b,a,c,f,e),$CLJS.y5.h?$CLJS.y5.h(a):$CLJS.y5.call(null,a)):a};A5=function(a){return $CLJS.FD(a,$CLJS.R,$CLJS.H([$CLJS.xD,$CLJS.p.h($CLJS.HD())]))};
Ksa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.qA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return A5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.ZV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Lsa=function(a,b,c){var d=function(){var l=$CLJS.zV(c);return $CLJS.n(l)?l:$CLJS.U.h($CLJS.ED(c))}(),e=A5($CLJS.AV(c,d));a=$CLJS.ok.j(a,$CLJS.aP,function(l){return $CLJS.nk.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.zV(b),k=$CLJS.qi.h($CLJS.ED(c));return Ksa(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.qi,k],null),d],null))};
B5=function(a,b,c){return $CLJS.vU(function(d){return $CLJS.E.g(d,$CLJS.n5(b))?$CLJS.n5(c):d},$CLJS.n($CLJS.zV(b))?Lsa(a,b,c):$CLJS.vU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Msa=function(a,b,c){a=$CLJS.V_.j(a,b,$CLJS.GV(a,b));b=$CLJS.V_.j(c,b,$CLJS.GV(c,b));return $CLJS.ff(function(d){return $CLJS.kk.g($CLJS.z(d),$CLJS.dd(d))},$CLJS.cf.j($CLJS.xr,a,b))};
Nsa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.LY.h(d),f=$CLJS.rV($CLJS.R.j(c,$CLJS.MJ,$CLJS.xY));return $CLJS.cf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,A5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Sa,function t(l,m){try{if($CLJS.ud(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ce(u,$CLJS.jF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.BU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.GV(a,b)))))};Osa=function(a,b,c){var d=$CLJS.qi.h($CLJS.ED(c));$CLJS.n(d)||(d=$CLJS.M0.h?$CLJS.M0.h(c):$CLJS.M0.call(null,c),d=$CLJS.Ra(d)||!$CLJS.yV(c));if($CLJS.n(d))return c;a=$CLJS.R_.j(a,b,c);return $CLJS.FD(c,$CLJS.R,$CLJS.H([$CLJS.qi,a]))};
Psa=function(a,b){a=$CLJS.zV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ra($CLJS.zV(b))&&$CLJS.Ra($CLJS.U.h($CLJS.ED(b))):a)?$CLJS.AV(b,a):b};
Qsa=function(a,b,c,d){d=Osa(a,b,Psa(c,d));c=$CLJS.RV.l(a,b,B5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.p5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Nsa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Msa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.RV.l(v,u,B5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Rsa=function(a,b){b=$CLJS.Pk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.sK)){var c=$CLJS.dd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Me(function(k){return $CLJS.Le($CLJS.ll,$CLJS.cf.j($CLJS.E,k,d))?k:null},s5(a,c)):f}();return $CLJS.n(e)?$CLJS.sk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Ssa=function(a,b,c,d){for(var e=Qsa(a,b,c,d);;){var f=$CLJS.HX($CLJS.MK,e),k=$CLJS.Fk.h($CLJS.Dl(function(l){return function(m){return Rsa(l,m)}}(e,f),$CLJS.Hr.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.C5.j(l,$CLJS.dd(m),$CLJS.Bz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Jl(t)),v=$CLJS.J.g(u,$CLJS.Cx);u=$CLJS.J.g(u,$CLJS.MV);if($CLJS.E.g(v,$CLJS.LV))return m=$CLJS.dd(m),$CLJS.x5.j?$CLJS.x5.j(l,m,u):$CLJS.x5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?z5(a,b,c,$CLJS.GF,d):e}};D5=function(a,b){return $CLJS.G1(a,$CLJS.jF)&&$CLJS.E.g($CLJS.h1(a),b)};Tsa=function(a,b,c){return function k(e,f){try{if(D5(f,b))return $CLJS.h3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.ZV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Usa=function(a,b,c){var d=$CLJS.FM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.VD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.kk.g(e,c):b)){var f=$CLJS.JV();$CLJS.c3(f,$CLJS.cf.g($CLJS.VD,d));c=f(c);return Tsa($CLJS.YK(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,b,$CLJS.VD],null),c),e,c)}return a};
Vsa=function(a,b,c){if($CLJS.Ad(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.lk.g(e,$CLJS.VD):e}();return $CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.El($CLJS.xr,$CLJS.FM.h($CLJS.GV(a,b))))};
E5=function(a,b,c,d){b=$CLJS.bt.g($CLJS.Wg($CLJS.X_.j(b,c,$CLJS.GV(b,c))),$CLJS.Wg($CLJS.X_.j(a,c,$CLJS.GV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.Ne.M(u5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Wsa=function(a,b,c){a=E5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,new $CLJS.h(null,1,[$CLJS.vO,$CLJS.eZ.h(d)],null),$CLJS.Ki.h(d)],null)});c=$CLJS.p5(a,c);return $CLJS.n(c)?E5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.N,$CLJS.LY.h(d)],null)}):a};
Xsa=function(a,b,c){return $CLJS.Ad(c)?$CLJS.Bz($CLJS.GV(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,c,$CLJS.VD],null)):$CLJS.sd(c)?$CLJS.VD.h(c):c};
F5=function(a,b,c,d){var e=Xsa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.RV)(a,b,function(k){var l=$CLJS.FM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.DD(k,$CLJS.FM,l)});return(0,$CLJS.RV)(f,b,function(k){return $CLJS.UV(k,$CLJS.FM,function(l){return $CLJS.nk.g(function(m){return $CLJS.p3(f,b,m)},l)})})}(),c=Wsa(c,a,b),$CLJS.y5.h?$CLJS.y5.h(c):$CLJS.y5.call(null,c)):a};
Ysa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function f(d,e){try{if(D5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.BU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Zsa=function(a,b){var c=$CLJS.RD.h($CLJS.GV(a,b));if($CLJS.n(c)){var d=$CLJS.Ra($CLJS.Me($CLJS.lk.g($CLJS.GM,$CLJS.ED),c));return d?r5(a,b,c,$CLJS.q5(a,b)):d}return c};
$sa=function(a,b){var c=$CLJS.GV(a,b),d=$CLJS.n(Zsa(a,b))?$CLJS.RV.l(a,b,$CLJS.ek,$CLJS.H([$CLJS.RD])):a;return $CLJS.n($CLJS.FM.h(c))?$CLJS.RV.l(d,b,$CLJS.ok,$CLJS.H([$CLJS.FM,$CLJS.Qe($CLJS.nk,function(e){var f=$CLJS.RD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wx,null,$CLJS.cz,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(r5(a,b,$CLJS.RD.h(e),$CLJS.V_.j(a,b,$CLJS.R.j(e,$CLJS.RD,$CLJS.wx))))?$CLJS.R.j(e,$CLJS.RD,$CLJS.wx):e})])):d};
$CLJS.y5=function(a){return $CLJS.cb($sa,a,$CLJS.bh(0,$CLJS.D($CLJS.sK.h(a))))};ata=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.G5=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var H5;$CLJS.C5=function(){function a(d,e,f){return $CLJS.sd(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.nK)?$CLJS.x5.j?$CLJS.x5.j(d,e,f):$CLJS.x5.call(null,d,e,f):z5(d,e,f,ata,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();H5=$CLJS.mE.h($CLJS.XD);
$CLJS.J5=function(){function a(d,e,f,k){if($CLJS.sd(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.nK))d=$CLJS.I5.v?$CLJS.I5.v(d,e,f,k):$CLJS.I5.call(null,d,e,f,k);else{var l=H5.h?H5.h(f):H5.call(null,f);l=$CLJS.n(l)?H5.h?H5.h(k):H5.call(null,k):l;d=$CLJS.n(l)?Ssa(d,e,f,k):z5(d,e,f,$CLJS.GF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.bta=function(){function a(d,e,f,k){f=Vsa(d,e,f);return $CLJS.n(f)?$CLJS.RV.l(d,e,Usa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.x5=function(){function a(d,e,f){try{return F5(d,e,f,function(u,v){return $CLJS.Be($CLJS.x0(function(x){return!($CLJS.E.g($CLJS.VD.h(x),v)||Ysa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Jl(k)),m=$CLJS.J.g(l,$CLJS.GK),t=$CLJS.J.g(l,$CLJS.Cx);l=$CLJS.J.g(l,$CLJS.MV);if($CLJS.E.g(t,$CLJS.LV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.I5=function(){function a(d,e,f,k){return null==k?$CLJS.x5.j(d,e,f):F5(d,e,f,function(l,m){return $CLJS.nk.g(function(t){return $CLJS.E.g($CLJS.VD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
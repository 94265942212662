var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var uY,zoa,Aoa,Boa,Coa,BY,Doa,Eoa,CY,Foa,EY,Goa,Hoa,Ioa,JY,KY,Joa,Koa,SY,Loa,Moa,Noa,XY,Ooa;uY=function(){};$CLJS.vY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(uY,a):$CLJS.Ya(uY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.wY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.xY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.yY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.zY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.AY=new $CLJS.M(null,"search","search",1564939822);BY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
CY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.DY=new $CLJS.M(null,"dataset","dataset",1159262238);EY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.FY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.GY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.HY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.IY=new $CLJS.M("source","joins","source/joins",1225821486);
JY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);KY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.LY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.MY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.NY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.OY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.PY=new $CLJS.M(null,"active","active",1895962068);$CLJS.QY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.RY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
SY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.TY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.UY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.VY=new $CLJS.M("source","native","source/native",-1444604147);
Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.WY=new $CLJS.M(null,"auto-list","auto-list",314197731);XY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.YY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.ZY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.$Y=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(JY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.dr,$CLJS.OY,$CLJS.VY,$CLJS.xY,$CLJS.zY,$CLJS.TY,$CLJS.lK,$CLJS.cK,$CLJS.IY,$CLJS.FY,$CLJS.HY],null));$CLJS.Y(CY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.WY,null,$CLJS.AY,null,$CLJS.gj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(SY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.kK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.iK],null)],null));
$CLJS.Y(BY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Zh],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Zh],null)],null)],null));
$CLJS.Y($CLJS.TJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.KJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.iK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.fr,$CLJS.zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.iK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.iK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZY,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.WJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,2,[$CLJS.ui,1,$CLJS.Gj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.UY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BY],null)],null)],
null)],null));$CLJS.Y(XY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null)],null)],null)],null));
$CLJS.Y(EY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PY,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,
$CLJS.pD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.jK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.WJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yY,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Fj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RY,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.Fj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,EY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.NJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Fj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.$Y,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Ii],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Li,$CLJS.Ii],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null)],null));
$CLJS.Y(KY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.vY},Coa,$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ip],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.vY)?$CLJS.vY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null)],null)],null)],null));
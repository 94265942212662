var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var JU,LU,MU,xma,QU,RU,SU,zma,TU,UU,VU,XU,YU,ZU,$U,aV,bV,dV,eV,fV,gV,hV,kV,lV,yma;JU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.mk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.KU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.xB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.pe(t,$CLJS.GD(x,function(){return function(A){return $CLJS.ek.l(JU(A),$CLJS.tA,$CLJS.H([$CLJS.qi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.GD(u,function(){return function(v){return $CLJS.ek.l(JU(v),$CLJS.tA,$CLJS.H([$CLJS.qi]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
LU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,T,da,oa,Ma){return function(sb){var Qa=$CLJS.I(sb,0,null);sb=$CLJS.I(sb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Ma,Qa),sb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.sd(k)?a(k):$CLJS.rd(k)?a($CLJS.El($CLJS.xr,k)):m}else return c}};MU=function(a,b,c){return $CLJS.ud(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Cd(b,$CLJS.J.g(c,2))};$CLJS.NU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.lk.g($CLJS.oD,$CLJS.dd)),$CLJS.aP.h(a));return LU($CLJS.ek.l(a,$CLJS.FM,$CLJS.H([$CLJS.pU])),function(c){return MU($CLJS.qA,b,c)})};
$CLJS.OU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.lk.g($CLJS.xD,$CLJS.dd)),$CLJS.ZE.h(a));return LU($CLJS.ek.l(a,$CLJS.FM,$CLJS.H([$CLJS.pU])),function(c){return MU($CLJS.ZE,b,c)})};xma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.FM.h(d)]))}function c(d){return $CLJS.ae($CLJS.VD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.sK.h(d)])))}return $CLJS.n($CLJS.PU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
QU=function(a){if($CLJS.rd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.zz(m,xma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(S,X,T,da,oa,Ma){return function Ja(Qa,Xa){try{if($CLJS.ud(Xa)&&3===$CLJS.D(Xa))try{var Ua=$CLJS.F(Xa,0);if($CLJS.ce(Ua,$CLJS.jF))try{var Ta=$CLJS.F(Xa,1);if($CLJS.n($CLJS.vO.h(Ta)))try{var mb=$CLJS.F(Xa,1);if(null!=mb?mb.C&256||$CLJS.yc===mb.mf||(mb.C?0:$CLJS.Ya($CLJS.ub,
mb)):$CLJS.Ya($CLJS.ub,mb))try{var dc=$CLJS.J.j(mb,$CLJS.vO,$CLJS.EU);if($CLJS.n($CLJS.Oe(da)(dc))){var Kd=$CLJS.J.g(mb,$CLJS.vO);$CLJS.F(Xa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Ma),": no join named ",$CLJS.xh.l($CLJS.H([Kd]))].join("")],null)}throw $CLJS.Z;}catch(gd){if(gd instanceof Error){var Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;
}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)return $CLJS.BU(Ja,Qa,Xa);throw Hd;}throw gd;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.ek.l(C,$CLJS.FM,$CLJS.H([yma]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};RU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);SU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);zma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);TU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
UU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);VU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.WU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);XU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
YU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);ZU=new $CLJS.r(null,"refs","refs",80480079,null);$U=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);aV=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);bV=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.cV=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);dV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);eV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);fV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);gV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
hV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.iV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.PU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.jV=new $CLJS.M(null,"filters","filters",974726919);kV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);lV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.mV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);yma=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y($U,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.cV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kP,$CLJS.Sa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.dl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HP,new $CLJS.h(null,1,[$CLJS.Ar,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hU],null)],null)],null));$CLJS.Y(eV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null));
$CLJS.Y(RU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),eV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.KU},SU,$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.tU,TU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[ZU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.KU)?$CLJS.KU.H:null]))],null)],null));
$CLJS.Y(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.KU},SU,$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.tU,TU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[ZU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.KU)?$CLJS.KU.H:null]))],null)],null));$CLJS.Y(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.HE],null),$CLJS.Fj,$CLJS.zj],null)],null));
$CLJS.Y(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),gV],null));
var nV=$CLJS.zz(function(a){a=$CLJS.z($CLJS.NU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.OU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,2,[$CLJS.ts,"Valid references for a single query stage",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.hj);return nV(a)}],null),$CLJS.Oe(nV)],null));
$CLJS.Y($CLJS.mV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.WU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.YE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),RU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),fV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jV,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),dV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PU,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Cd(a,$CLJS.JP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,
new $CLJS.h(null,1,[$CLJS.ts,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.dD($CLJS.gO,$CLJS.PU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null));$CLJS.Y(UU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PJ],null)],null)],null)],null));
$CLJS.Y(VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WJ],null)],null)],null)],null));$CLJS.Y(YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null)],null));
$CLJS.Y(aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.zz($CLJS.gO,$CLJS.PU))],null)],null));$CLJS.Y(bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.cV,$CLJS.WU],null));
$CLJS.Y(zma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,bV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Vs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$U],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mV],null)],null)],null)],null));
$CLJS.Y(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,bV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Vs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$U],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YU],null)],null)],null)],null));$CLJS.Y(XU,aV);
$CLJS.Y(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,2,[$CLJS.ts,"Valid references for all query stages",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.hj);return QU.h?QU.h(a):QU.call(null,a)}],null),$CLJS.Oe(QU)],null));
$CLJS.Y($CLJS.oK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null));
$CLJS.Y($CLJS.MK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.iV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.XJ,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oK],null)],null)],null),$CLJS.pma],null));
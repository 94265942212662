var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var TK,UK,VK,nja,oja,ZK,pja,bL,cL,dL,eL,qja,fL,gL,iL,jL,WK,kL,rja,lL,sja,mL,nL,oL,tja,pL,qL,uja,rL,vja,sL,tL,wja,vL,xja,yja,zja,xL,yL,zL,AL,BL,CL,DL,EL,Aja,FL,GL,HL,JL,KL,Bja,LL,ML,NL,OL,Cja,PL,QL,RL,SL,Dja,TL,UL,VL,WL,Eja,XL,Fja,YL,ZL,$L,Gja,aM,bM,cM,dM,fM,gM,Hja,hM,iM,Ija,jM,kM,lM,mM,nM,Jja,oM,Kja,pM,qM,sM,Lja,tM,uM,vM,Mja,yM,zM,Nja,Oja,AM,CM,DM,EM,Pja,HM,IM,Qja,Rja,Sja,JM,KM,LM,MM,NM,OM,Tja,PM,Uja,QM,RM,TM,VM,WM,XM,YM,Vja,Wja,$M,Xja,Yja,aN,bN,cN,dN,eN,Zja,fN,$ja,aka,gN,hN,iN,jN,kN,
bka,cka,dka,lN,eka,mN,fka,oN,pN,gka,rN,sN,tN,uN,hka,xN,ika,jka,yN,zN,AN,kka,BN,CN,DN,EN,lka,GN,HN,IN,JN,KN,mka,LN,MN,nka,NN,ON,PN,QN,RN,SN,UN,oka,VN,pka,WN,XN,YN,qka,rka,ska,$N,aO,bO,tka,cO,dO,eO,fO,uka,vka,hO,wka,xka,iO,jO,kO,lO,mO,yka,nO,oO,pO,qO,zka,rO,sO,tO,uO,wO,Aka,xO,yO,Bka,zO,AO,BO,Cka,CO,Dka,EO,Eka,FO,GO,HO,IO,JO,KO,LO,Fka,Gka,Hka,MO,Ika,NO,OO,Jka,Kka,PO,QO,RO,Lka,SO,Mka,TO,Nka,Oka,UO,Pka,Qka,Rka,XO,YO,Ska,ZO,$O,bP,Tka,cP,Uka,dP,eP,fP,Vka,gP,hP,iP,jP,Wka,lP,mP,Xka,nP,oP,pP,qP,rP,sP,tP,uP,
Yka,vP,wP,Zka,xP,$ka,yP,zP,AP,ala,BP,CP,bla,cla,DP,EP,FP,dla,ela,GP,fla,gla,hla,IP,ila,KP,jla,kla,LP,MP,lla,mla,nla,NP,OP,PP,QP,RP,SP,TP,ola,UP,VP,pla,WP,qla,rla,sla,XP,YP,ZP,tla,$P,aQ,ula,bQ,vla,cQ,wla,xla,eQ,fQ,gQ,hQ,iQ,yla,jQ,kQ,zla,lQ,mQ,nQ,oQ,pQ,qQ,rQ,sQ,Ala,tQ,uQ,wQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,EQ,FQ,Bla,GQ,HQ,IQ,JQ,KQ,Cla,LQ,MQ,NQ,OQ,Dla,QQ,Ela,Fla,SQ,Gla,TQ,Hla,UQ,Ila,Jla,VQ,Kla,WQ,YQ,ZQ,Lla,Mla,cR,dR,Nla,Ola,eR,fR,gR,hR,iR,Pla,jR,kR,mR,nR,Qla;
$CLJS.SK=function(a){var b=$CLJS.En.g(a,null),c=$CLJS.lE(b,$CLJS.Br,function(d){return $CLJS.Km(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Sk,b,$CLJS.hj,k,$CLJS.Hr,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
TK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};UK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Nm($CLJS.En.g($CLJS.Sk.h(a),null));if($CLJS.n(b)){var e=TK($CLJS.Es.h(b),c);$CLJS.n(e)?(e=$CLJS.Cp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:TK($CLJS.ts.h(b),c)}return null};VK=function(a,b){return $CLJS.qd(a)||$CLJS.jl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Sk),d=$CLJS.J.g(a,$CLJS.Vi);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Hr,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Fs),l=$CLJS.J.j(b,$CLJS.Cs,$CLJS.As),m=UK(a,$CLJS.Ep.h(c),k,b);if($CLJS.n(m))return m;m=UK(a,$CLJS.Tr.h(c),k,b);if($CLJS.n(m))return m;m=UK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=UK(a,function(){var t=$CLJS.qn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=UK(a,$CLJS.Ep.h(c),
l,b);if($CLJS.n(m))return m;m=UK(a,$CLJS.Tr.h(c),l,b);if($CLJS.n(m))return m;d=UK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=UK(a,function(){var t=$CLJS.qn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?UK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?UK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Pk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Sk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Fs);l=$CLJS.J.j(l,$CLJS.Cs,$CLJS.As);k=$CLJS.Ep.h(k);f=TK($CLJS.Bs.h(k),f);f=$CLJS.n(f)?f:TK($CLJS.Bs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.XK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.hj);a=$CLJS.J.g(b,$CLJS.Hr);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.Bi),f=$CLJS.J.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Bi,m);l=e.h?e.h(l):e.call(null,l);return WK(k,c,t,l)},null,a):null};
ZK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.ZC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.ZC,$CLJS.YK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.$K=function(a){return ZK($CLJS.Tj,a,function(){return $CLJS.mE.h(a)})};pja=function(){var a=aL;return ZK($CLJS.Br,a,function(){var b=$CLJS.mE.h(a),c=$CLJS.SK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
bL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};cL=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,bL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,bL(c)],null);default:return bL(a)}}else return bL(a)};
dL=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
eL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(dL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,cL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,cL(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Wr(2,2,b))}())],null)};qja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
fL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.zi,qja,$CLJS.ts,["valid instance of one of these MBQL clauses: ",$CLJS.hs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
gL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RK],null)],null)};$CLJS.hL={};iL={};jL={};$CLJS.YK=function YK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=YK.j?YK.j(k,f,c):YK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
WK=function WK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=VK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.zs.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=VK(f,c),d=WK.v?WK.v(b,l,k,d):WK.call(null,b,l,k,d),$CLJS.ol(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.jl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),WK.v?WK.v(f,b,c,d):WK.call(null,f,b,c,d)):$CLJS.n($CLJS.zs.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.zs,!0],null))};kL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
lL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);mL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);nL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);oL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);pL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);qL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);rL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);sL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);tL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.uL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);vL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.wL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);xL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
yL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);zL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);AL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);BL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);CL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
DL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);EL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);FL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);GL=new $CLJS.r(null,"stddev","stddev",775056588,null);HL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.IL=new $CLJS.M(null,"snippet","snippet",953581994);JL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);KL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);LL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);ML=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
NL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);OL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);PL=new $CLJS.M("location","country","location/country",1666636202);QL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
RL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);SL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);TL=new $CLJS.M(null,"unary","unary",-989314568);UL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
VL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);WL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);XL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);YL=new $CLJS.r(null,"count-where","count-where",2025939247,null);ZL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
$L=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);aM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);bM=new $CLJS.r(null,"sum","sum",1777518341,null);cM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);dM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.eM=new $CLJS.M("date","range","date/range",1647265776);fM=new $CLJS.r(null,"between","between",-1523336493,null);gM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);hM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);iM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
jM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);kM=new $CLJS.r(null,"not-null","not-null",313812992,null);lM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);mM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);nM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
oM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);pM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);qM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.rM=new $CLJS.M(null,"context","context",-830191113);sM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);tM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);uM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);vM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.wM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.xM=new $CLJS.M(null,"card","card",-1430355152);yM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);zM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);AM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.BM=new $CLJS.M("date","month-year","date/month-year",1948031290);
CM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);DM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);EM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.FM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.GM=new $CLJS.M(null,"source-field","source-field",933829534);HM=new $CLJS.r(null,"Field","Field",430385967,null);
IM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);JM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);KM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
LM=new $CLJS.M(null,"more","more",-2058821800);MM=new $CLJS.M(null,"first-clause","first-clause",-20953491);NM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);OM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);PM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);QM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);RM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.SM=new $CLJS.M(null,"widget-type","widget-type",1836256899);TM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.UM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
VM=new $CLJS.r(null,"is-null","is-null",-356519403,null);WM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);XM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);YM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.ZM=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
$M=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);aN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);bN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);cN=new $CLJS.M("string","contains","string/contains",1602423827);dN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
eN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);fN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
gN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);hN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);iN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);jN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);kN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);lN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
mN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.nN=new $CLJS.M(null,"collection","collection",-683361892);oN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);pN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.qN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);rN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);sN=new $CLJS.r(null,"metric","metric",2049329604,null);tN=new $CLJS.r(null,"concat","concat",-467652465,null);uN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.vN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.wN=new $CLJS.M("date","relative","date/relative",25987732);xN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);yN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);zN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
AN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);BN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);CN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);DN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);EN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.FN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);GN=new $CLJS.r(null,"*","*",345799209,null);HN=new $CLJS.r(null,"+","+",-740910886,null);IN=new $CLJS.r(null,"-","-",-471816912,null);JN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);KN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);LN=new $CLJS.r(null,"asc","asc",1997386096,null);
MN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);NN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);ON=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);PN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);QN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);RN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
SN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.TN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);UN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);VN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
WN=new $CLJS.r(null,"and","and",668631710,null);XN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);YN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.ZN=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);$N=new $CLJS.r(null,"exp","exp",1378825265,null);
aO=new $CLJS.r(null,"Filter","Filter",-424893332,null);bO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);cO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);dO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);eO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
fO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.gO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);hO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);iO=new $CLJS.M(null,"requires-features","requires-features",-101116256);jO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);kO=new $CLJS.M(null,"clause-name","clause-name",-996419059);lO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);mO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);nO=new $CLJS.r(null,"not","not",1044554643,null);oO=new $CLJS.r(null,"avg","avg",1837937727,null);pO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);qO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);rO=new $CLJS.r(null,"case","case",-1510733573,null);
sO=new $CLJS.r(null,"distinct","distinct",-148347594,null);tO=new $CLJS.r(null,"get-second","get-second",-425414791,null);uO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.vO=new $CLJS.M(null,"join-alias","join-alias",1454206794);wO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
xO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);yO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);zO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);AO=new $CLJS.r(null,"abs","abs",1394505050,null);BO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
CO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.DO=new $CLJS.M(null,"date","date",-1463434462);EO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);FO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
GO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);HO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);IO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);JO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);KO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);LO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);MO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);NO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
OO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);PO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);QO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
RO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);SO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);TO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);UO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.VO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.WO=new $CLJS.M(null,"database","database",1849087575);XO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);YO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);ZO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
$O=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.aP=new $CLJS.M(null,"expressions","expressions",255689909);bP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);cP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);dP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);eP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);fP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
gP=new $CLJS.r(null,"get-day","get-day",1768100384,null);hP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);iP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);jP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.kP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);lP=new $CLJS.M(null,"page","page",849072397);
mP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);nP=new $CLJS.r(null,"get-week","get-week",752472178,null);oP=new $CLJS.r(null,"get-month","get-month",1271156796,null);pP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);qP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);rP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
sP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);tP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);uP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);vP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);wP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);xP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);yP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);zP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
AP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);BP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);CP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);DP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);EP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);FP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);GP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.HP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);IP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.JP=new $CLJS.M(null,"source-query","source-query",198004422);KP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);LP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);MP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);NP=new $CLJS.M(null,"amount","amount",364489504);OP=new $CLJS.r(null,"percentile","percentile",1039342775,null);PP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);QP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);RP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
SP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);TP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);UP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);VP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
WP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);XP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);YP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
ZP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);$P=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);aQ=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);bQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);cQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.dQ=new $CLJS.M(null,"card-id","card-id",-1770060179);eQ=new $CLJS.M(null,"variadic","variadic",882626057);
fQ=new $CLJS.r(null,"upper","upper",1886775433,null);gQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);hQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);iQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);jQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
kQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);lQ=new $CLJS.r(null,"power","power",702679448,null);mQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);nQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);oQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
pQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);qQ=new $CLJS.r(null,"median","median",-2084869638,null);rQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);sQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);tQ=new $CLJS.M(null,"y","y",-1757859776);uQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.vQ=new $CLJS.M(null,"binning","binning",1709835866);wQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);xQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);yQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);zQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);AQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);BQ=new $CLJS.M(null,"b","b",1482224470);
CQ=new $CLJS.M(null,"a","a",-2123407586);DQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);EQ=new $CLJS.r(null,"replace","replace",853943757,null);FQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);GQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
HQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);IQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);JQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);KQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);LQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
MQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);NQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);OQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.PQ=new $CLJS.M(null,"order-by","order-by",1527318070);QQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.RQ=new $CLJS.M(null,"condition","condition",1668437652);SQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);TQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);UQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);VQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
WQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.XQ=new $CLJS.M(null,"database_type","database_type",-54700895);YQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);ZQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.$Q=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.aR=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.bR=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);cR=new $CLJS.M("location","state","location/state",-114378652);dR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
eR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);fR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);gR=new $CLJS.M(null,"numeric","numeric",-1495594714);hR=new $CLJS.r(null,"variable","variable",1359185035,null);iR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
jR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);kR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.lR=new $CLJS.M(null,"limit","limit",-1355822363);mR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);nR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pD],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lD],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hD],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iK],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PJ],null),uR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.IH],null),vR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MH],null),wR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DH],null),xR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.ci,null,$CLJS.Qj,null,$CLJS.Xi,null,$CLJS.Oh,null,$CLJS.ri,null,$CLJS.cj,null,$CLJS.Ij,null,$CLJS.lj,null,$CLJS.Wh,null,$CLJS.jj,null,$CLJS.di,null,$CLJS.mi,null],null),null),yR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Nj,null,$CLJS.SF,null,$CLJS.Oh,null,$CLJS.hi,null,$CLJS.VF,null,$CLJS.Ti,null,$CLJS.Ei,null],null),null),
zR=$CLJS.Ws.g(xR,yR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"date bucketing unit"],null)],null),xR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"time bucketing unit"],null)],null),yR),AR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"datetime bucketing unit"],null)],null),zR),BR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,
1,[$CLJS.ts,"temporal extract unit"],null),$CLJS.WF,$CLJS.mi,$CLJS.Ij,$CLJS.YH,$CLJS.lI,$CLJS.mI,$CLJS.lj,$CLJS.ri,$CLJS.hi,$CLJS.Ti,$CLJS.TF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"datetime-diff unit"],null),$CLJS.SF,$CLJS.Ei,$CLJS.Nj,$CLJS.Qj,$CLJS.Xi,$CLJS.cj,$CLJS.ci,$CLJS.Wh],null),CR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"temporal-extract week extraction mode"],null),$CLJS.SH,$CLJS.ZH,$CLJS.cI],null),DR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Ei,$CLJS.Nj,$CLJS.Qj,$CLJS.Xi,$CLJS.cj,$CLJS.ci,$CLJS.Wh],null),KR,Yla,SR,TR,UR,VR,WR,YR,ZR,LT,$la,MT,ama,bma,NT,cma,dma,ema;$CLJS.Y(VL,eL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Qu],null),$CLJS.ji],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,DR],null)])));var ER=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VL],null);
$CLJS.Y(zO,eL($CLJS.ZA,$CLJS.H(["n",$CLJS.ji,"unit",DR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zO],null);
$CLJS.Y(pM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"valid :absolute-datetime clause",$CLJS.zi,function(a){if($CLJS.Ra(dL($CLJS.WH,a)))a=oM;else{a=$CLJS.dd(a);var b=$CLJS.$K(uR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.DO:$CLJS.$H}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[oM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DO,eL($CLJS.WH,
$CLJS.H(["date",uR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,eL($CLJS.WH,$CLJS.H(["datetime",vR,"unit",AR]))],null)],null));var FR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);$CLJS.Y(EM,eL($CLJS.Fx,$CLJS.H(["time",wR,"unit",Ula])));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null),HR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"date or datetime literal"],null),FR,vR,uR],null);
$CLJS.Y(pL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"time literal"],null),GR,wR],null));$CLJS.Y(eR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"temporal literal"],null),HR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pL],null)],null));var IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eR],null);
$CLJS.Y(pla,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof FR?new $CLJS.Cc(function(){return FR},$CLJS.kd(Qja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,xP,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fP,"metabase/mbql/schema.cljc",69,$CLJS.WH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(FR)?FR.H:null])):null));return $CLJS.n(a)?a:fP}(),FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof ER?new $CLJS.Cc(function(){return ER},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,
$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Oj,$CLJS.V($CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Qu],null),$CLJS.ji],null)),$CLJS.ei,$CLJS.V($CLJS.tj,$CLJS.V(iQ,VN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:xO}(),ER],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,xP,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.tj,ola),$CLJS.ei,$CLJS.V($CLJS.tj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:WQ}(),GR],null)])));
$CLJS.Y(UQ,eL($CLJS.hj,$CLJS.H(["value",$CLJS.Zh,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cC,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.fr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,AR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,oR],null)],null)],null)],null)])));var JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);$CLJS.Y(EN,eL($CLJS.qA,$CLJS.H(["expression-name",oR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,$CLJS.Fj],null)])));
KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BD);c=$CLJS.J.g(c,$CLJS.iD);return $CLJS.E.g(b,$CLJS.iD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BD);c=$CLJS.J.g(c,$CLJS.AD);return $CLJS.E.g(b,$CLJS.AD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"binning strategy"],null),$CLJS.iD,$CLJS.AD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),rR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.wl)],null)],null)],null)],null)));$CLJS.LR=function LR(a){switch(arguments.length){case 1:return LR.h(arguments[0]);case 2:return LR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.LR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.pF);return $CLJS.LR.g(a,b)};$CLJS.LR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.Jk)?xR:$CLJS.Dz(a,$CLJS.Ok)?yR:$CLJS.Dz(a,$CLJS.Lk)?zR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.LR.A=2;
$CLJS.Y(jQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,AR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.LR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.BD)],null)],null));
$CLJS.Y(LQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(eL($CLJS.jF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,tR,oR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null)],null)]))));var MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);
$CLJS.Y(MO,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof KR?new $CLJS.Cc(function(){return KR},$CLJS.kd(QM,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.aR,$CLJS.V($CLJS.tj,XL),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,$CLJS.Fj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(KR)?KR.H:null])):null));return $CLJS.n(a)?a:AM}(),KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof MR?new $CLJS.Cc(function(){return MR},
$CLJS.kd(hN,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.fq,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],["0.39.0",$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(MR)?MR.H:null])):null));return $CLJS.n(a)?a:iM}(),MR],null)])));
$CLJS.NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);$CLJS.Y(kL,eL($CLJS.ZE,$CLJS.H(["aggregation-clause-index",$CLJS.ji,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,$CLJS.Fj],null)])));var OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kL],null);
$CLJS.Y(RL,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof OR?new $CLJS.Cc(function(){return OR},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uQ,"metabase/mbql/schema.cljc",23,$CLJS.ZE,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZE,Nla,$CLJS.V($CLJS.tj,$CLJS.ji),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,$CLJS.Fj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(OR)?OR.H:null])):null));return $CLJS.n(a)?a:uQ}(),OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof KR?new $CLJS.Cc(function(){return KR},
$CLJS.kd(QM,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.aR,$CLJS.V($CLJS.tj,XL),
$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,$CLJS.Fj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(KR)?KR.H:null])):null));return $CLJS.n(a)?a:AM}(),KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof MR?new $CLJS.Cc(function(){return MR},$CLJS.kd(hN,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.fq,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,
$CLJS.fi,$CLJS.Hj,$CLJS.Kj],["0.39.0",$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(MR)?MR.H:null])):null));return $CLJS.n(a)?a:iM}(),MR],null)])));
var PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RL],null),QR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.js,null,$CLJS.AF,null,$CLJS.BE,null,$CLJS.GF,null,$CLJS.IE,null,$CLJS.mF,null,$CLJS.SE,null,$CLJS.ps,null,$CLJS.yF,null,$CLJS.lF,null,$CLJS.HF,null],null),null),RR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OL],null);
$CLJS.Y(rL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,function(a){return"string"===typeof a?$CLJS.zj:$CLJS.n(dL(QR,a))?KL:$CLJS.n(dL($CLJS.hj,a))?$CLJS.hj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[KL,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.NR],null)],null));SR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rL],null);
TR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.EE,null,$CLJS.kI,null,$CLJS.Nq,null,$CLJS.cB,null,$CLJS.fI,null,$CLJS.XE,null,$CLJS.Zr,null,$CLJS.AE,null,$CLJS.iI,null,$CLJS.aB,null,$CLJS.mF,null,$CLJS.DF,null,$CLJS.gI,null,$CLJS.oF,null,$CLJS.GE,null,$CLJS.UH,null,$CLJS.Mq,null,$CLJS.JE,null,$CLJS.SE,null,$CLJS.OH,null,$CLJS.Pw,null,$CLJS.VH,null,$CLJS.dI,null,$CLJS.YA,null,$CLJS.FF,null,$CLJS.hF,null],null),null);
UR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Jq,null,$CLJS.Dq,null,$CLJS.gr,null,$CLJS.ir,null,$CLJS.Fq,null,$CLJS.zF,null,$CLJS.nr,null,$CLJS.Kq,null,$CLJS.Hq,null],null),null);VR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.ui,null,$CLJS.xF,null,$CLJS.sF,null,$CLJS.kF,null,$CLJS.CF,null,$CLJS.EF,null,$CLJS.OE,null,$CLJS.VE,null,$CLJS.wL,null,$CLJS.PE,null,$CLJS.wF,null,$CLJS.qF,null,$CLJS.Gj,null,$CLJS.Rw,null,$CLJS.dF,null,$CLJS.eF,null,$CLJS.vF,null],null),null);
WR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.aI,null,$CLJS.QH,null,$CLJS.eB,null,$CLJS.Mq,null,$CLJS.bB,null],null),null);YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);$CLJS.$R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);$CLJS.aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);
$CLJS.Y(oQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"numeric expression argument",$CLJS.zi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.n(dL(TR,a))?fO:$CLJS.n(dL(VR,a))?$CLJS.ZE:$CLJS.n(dL($CLJS.hj,a))?$CLJS.hj:$CLJS.jF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[fO,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jF,
$CLJS.NR],null)],null));var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);
$CLJS.Y(yP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"datetime expression argument",$CLJS.zi,function(a){return $CLJS.n(dL(VR,a))?$CLJS.ZE:$CLJS.n(dL($CLJS.hj,a))?$CLJS.hj:$CLJS.n(dL(WR,a))?JM:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[JM,$CLJS.$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,HR,$CLJS.NR],null)],null)],null));
var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null);
$CLJS.Y(bP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"expression argument",$CLJS.zi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.xd(a)?$CLJS.ur:$CLJS.n(dL(UR,a))?qP:$CLJS.n(dL(TR,a))?fO:$CLJS.n(dL(WR,a))?JM:"string"===typeof a?$CLJS.zj:$CLJS.n(dL(QR,a))?KL:$CLJS.n(dL($CLJS.hj,a))?$CLJS.hj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[qP,ZR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[fO,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[JM,$CLJS.$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[KL,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.NR],null)],null));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null);$CLJS.Y($O,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"numeric expression arg or interval"],null),Xla,bS],null));
var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null);$CLJS.Y(CL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"int greater than zero or numeric expression",$CLJS.zi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,rR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,YR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CL],null);
$CLJS.Y(KM,eL($CLJS.mF,$CLJS.H(["a",dS,"b",dS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,dS],null)])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);$CLJS.Y(oL,eL($CLJS.BE,$CLJS.H(["s",SR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,bS],null)])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oL],null);$CLJS.Y(rP,eL($CLJS.Pw,$CLJS.H(["s",SR])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);$CLJS.Y(QL,eL($CLJS.yF,$CLJS.H(["s",SR])));
var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null);$CLJS.Y(UL,eL($CLJS.HF,$CLJS.H(["s",SR])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UL],null);$CLJS.Y(mM,eL($CLJS.AF,$CLJS.H(["s",SR])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);$CLJS.Y(YM,eL($CLJS.js,$CLJS.H(["s",SR])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.Y(HL,eL($CLJS.ps,$CLJS.H(["s",SR])));var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HL],null);
$CLJS.Y(nQ,eL($CLJS.GF,$CLJS.H(["s",SR,"match",$CLJS.zj,"replacement",$CLJS.zj])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);$CLJS.Y(jR,eL($CLJS.IE,$CLJS.H(["a",SR,"b",SR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,SR],null)])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null);$CLJS.Y(NN,eL($CLJS.lF,$CLJS.H(["s",SR,"pattern",$CLJS.zj])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);
$CLJS.Y(JQ,eL($CLJS.Mq,$CLJS.H(["x",eS,"y",eS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,eS],null)])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.Y(HQ,eL($CLJS.Zr,$CLJS.H(["x",bS,"y",eS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,eS],null)])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.Y(GQ,eL($CLJS.DF,$CLJS.H(["x",bS,"y",bS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,bS],null)])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);
$CLJS.Y(IQ,eL($CLJS.Nq,$CLJS.H(["x",bS,"y",bS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,bS],null)])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.Y(ZO,eL($CLJS.hF,$CLJS.H(["x",bS])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);$CLJS.Y(RP,eL($CLJS.EE,$CLJS.H(["x",bS])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.Y(mR,eL($CLJS.oF,$CLJS.H(["x",bS])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null);$CLJS.Y(dO,eL($CLJS.JE,$CLJS.H(["x",bS])));
var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.Y(jO,eL($CLJS.GE,$CLJS.H(["x",bS,"y",bS])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.Y(GO,eL($CLJS.XE,$CLJS.H(["x",bS])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.Y(DL,eL($CLJS.AE,$CLJS.H(["x",bS])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DL],null);$CLJS.Y(eP,eL($CLJS.FF,$CLJS.H(["x",bS])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eP],null);
$CLJS.Y(cM,eL($CLJS.YA,$CLJS.H(["datetime-x",cS,"datetime-y",cS,"unit",Wla])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cM],null);$CLJS.Y(uM,eL($CLJS.cB,$CLJS.H(["datetime",cS,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,CR],null)])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.Y(lO,eL($CLJS.VH,$CLJS.H(["date",cS])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.Y(NO,eL($CLJS.kI,$CLJS.H(["date",cS])));
var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);$CLJS.Y(qL,eL($CLJS.OH,$CLJS.H(["date",cS])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qL],null);$CLJS.Y(kR,eL($CLJS.aB,$CLJS.H(["date",cS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,CR],null)])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null);$CLJS.Y(yN,eL($CLJS.dI,$CLJS.H(["date",cS])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.Y(HO,eL($CLJS.gI,$CLJS.H(["date",cS])));
var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.Y(ZL,eL($CLJS.iI,$CLJS.H(["datetime",cS])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZL],null);$CLJS.Y(DN,eL($CLJS.fI,$CLJS.H(["datetime",cS])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null);$CLJS.Y(SL,eL($CLJS.UH,$CLJS.H(["datetime",cS])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SL],null);$CLJS.Y(iN,eL($CLJS.QH,$CLJS.H(["datetime",cS,"to",BR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,BR],null)])));
var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null),PS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"datetime arithmetic unit"],null),$CLJS.VF,$CLJS.SF,$CLJS.Ei,$CLJS.Nj,$CLJS.Qj,$CLJS.Xi,$CLJS.cj,$CLJS.ci,$CLJS.Wh],null);$CLJS.Y(nL,eL($CLJS.bB,$CLJS.H(["datetime",cS,"amount",bS,"unit",PS])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nL],null);
$CLJS.Y(LL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return eL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.aI));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LL],null);$CLJS.Y(mL,eL($CLJS.eB,$CLJS.H(["datetime",cS,"amount",bS,"unit",PS])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);
$CLJS.Y(DP,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.kd(TO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.Mq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mq,$CLJS.yD,$CLJS.V($CLJS.tj,XO),tQ,$CLJS.V($CLJS.tj,XO),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,XO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:HN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},
$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[bN,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.$H,$CLJS.V($CLJS.tj,fR),NP,$CLJS.V($CLJS.tj,
SP),$CLJS.ei,$CLJS.V($CLJS.tj,$P)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:OO}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(rja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,
$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[bN,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.$H,$CLJS.V($CLJS.tj,fR),NP,$CLJS.V($CLJS.tj,SP),$CLJS.ei,$CLJS.V($CLJS.tj,$P)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(SS)?SS.H:null])):null));
return $CLJS.n(a)?a:dR}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.QH,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IM,"metabase/mbql/schema.cljc",71,$CLJS.QH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QH,$CLJS.$H,$CLJS.V($CLJS.tj,fR),qka,$CLJS.V($CLJS.tj,wQ),vja,$CLJS.V($CLJS.tj,$CLJS.V(iQ,wQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:IM}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&
"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aI,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",
45,$CLJS.aI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aI],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:mO}(),RS],null)])));$CLJS.TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);$CLJS.Y(sL,eL($CLJS.nr,$CLJS.H(["first-clause",$CLJS.TS,"second-clause",$CLJS.TS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,$CLJS.TS],null)])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sL],null);
$CLJS.Y(eN,eL($CLJS.gr,$CLJS.H(["first-clause",$CLJS.TS,"second-clause",$CLJS.TS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,$CLJS.TS],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);$CLJS.Y(NL,eL($CLJS.ir,$CLJS.H(["clause",$CLJS.TS])));
var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NL],null),XS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,3,[$CLJS.ts,":field or :expression reference or :relative-datetime",$CLJS.Es,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.zi,function(a){return $CLJS.n(dL($CLJS.$A,a))?$CLJS.$A:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,ER],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.NR],null)],null);
$CLJS.Y(AP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gr,$CLJS.ur,$CLJS.bl,$CLJS.zj,IR,XS,dS,JR],null)],null));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AP],null);
$CLJS.Y(SN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"order comparable",$CLJS.zi,function(a){return $CLJS.n(dL($CLJS.hj,a))?$CLJS.hj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,$CLJS.bl,$CLJS.zj,IR,dS,XS],null)],null)],null));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SN],null);
$CLJS.Y(zQ,eL($CLJS.Kq,$CLJS.H(["field",YS,"value-or-field",YS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,YS],null)])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.Y(DQ,eL($CLJS.zF,$CLJS.H(["field",YS,"value-or-field",YS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,YS],null)])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);$CLJS.Y(yQ,eL($CLJS.Hq,$CLJS.H(["field",ZS,"value-or-field",ZS])));
var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.Y(xQ,eL($CLJS.Dq,$CLJS.H(["field",ZS,"value-or-field",ZS])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.Y(nM,eL($CLJS.Jq,$CLJS.H(["field",ZS,"value-or-field",ZS])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.Y(tM,eL($CLJS.Fq,$CLJS.H(["field",ZS,"value-or-field",ZS])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.Y(iP,eL($CLJS.LE,$CLJS.H(["field",ZS,"min",ZS,"max",ZS])));
var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);$CLJS.Y(KP,eL($CLJS.RE,$CLJS.H(["lat-field",ZS,"lon-field",ZS,"lat-max",ZS,"lon-min",ZS,"lat-min",ZS,"lon-max",ZS])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.Y(XM,eL($CLJS.KE,$CLJS.H(["field",$CLJS.NR])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.Y(MN,eL($CLJS.WE,$CLJS.H(["field",$CLJS.NR])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null);$CLJS.Y(oN,eL($CLJS.ME,$CLJS.H(["field",$CLJS.NR])));
var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.Y(vL,eL($CLJS.TE,$CLJS.H(["field",$CLJS.NR])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vL],null),lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null);$CLJS.Y(BN,eL($CLJS.aF,$CLJS.H(["field",SR,"string-or-field",SR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,lT],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);
$CLJS.Y(uO,eL($CLJS.uF,$CLJS.H(["field",SR,"string-or-field",SR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,lT],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.Y(mN,eL($CLJS.iF,$CLJS.H(["field",SR,"string-or-field",SR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,lT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.Y(cP,eL($CLJS.bF,$CLJS.H(["field",SR,"string-or-field",SR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,lT],null)])));
var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.Y(EL,eL($CLJS.dB,$CLJS.H(["field",$CLJS.NR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.ji,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null),"unit",DR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Nk,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null)],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EL],null);
$CLJS.Y(tP,eL($CLJS.HE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null),oR],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tP],null);
$CLJS.Y(CM,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(mQ,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WN,"metabase/mbql/schema.cljc",15,$CLJS.nr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nr,MM,$CLJS.V($CLJS.tj,aO),EO,$CLJS.V($CLJS.tj,aO),WM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,aO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:WN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},
$CLJS.kd(IO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",14,$CLJS.gr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gr,MM,$CLJS.V($CLJS.tj,aO),EO,$CLJS.V($CLJS.tj,aO),WM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,aO))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:LO}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(uP,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",15,$CLJS.ir,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.$Q,$CLJS.V($CLJS.tj,aO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:nO}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==
typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:PN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(TQ,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",14,$CLJS.Jq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:ON}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&
"undefined"!==typeof jL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Dq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.jF,$CLJS.V($CLJS.tj,
OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:QN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(MQ,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],
[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XN,"metabase/mbql/schema.cljc",14,$CLJS.Fq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:XN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&
"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Kq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Kq,$CLJS.jF,$CLJS.V($CLJS.tj,YQ),YP,$CLJS.V($CLJS.tj,YQ),BP,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,YQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:RN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(dM,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,
$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zM,"metabase/mbql/schema.cljc",14,$CLJS.zF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zF,$CLJS.jF,$CLJS.V($CLJS.tj,YQ),YP,$CLJS.V($CLJS.tj,YQ),BP,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,YQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(aT)?aT.H:null])):null));
return $CLJS.n(a)?a:zM}(),aT],null)])));
$CLJS.Y(JO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"valid filter expression",$CLJS.zi,function(a){return $CLJS.n(dL(WR,a))?$CLJS.$H:$CLJS.n(dL(TR,a))?gR:$CLJS.n(dL(QR,a))?$CLJS.zj:$CLJS.n(dL(UR,a))?$CLJS.ur:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,$CLJS.$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[gR,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,fL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(mQ,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",
15,$CLJS.nr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nr,MM,$CLJS.V($CLJS.tj,aO),EO,$CLJS.V($CLJS.tj,aO),WM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,aO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:WN}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(IO,new $CLJS.h(null,1,[$CLJS.Aj,
!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",14,$CLJS.gr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gr,MM,$CLJS.V($CLJS.tj,aO),EO,$CLJS.V($CLJS.tj,aO),WM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,aO))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(VS)?
VS.H:null])):null));return $CLJS.n(a)?a:LO}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(uP,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nO,"metabase/mbql/schema.cljc",15,$CLJS.ir,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.$Q,$CLJS.V($CLJS.tj,aO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:nO}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,
$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Kq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.jF,$CLJS.V($CLJS.tj,YQ),YP,$CLJS.V($CLJS.tj,YQ),BP,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,YQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n($S)?$S.H:null])):
null));return $CLJS.n(a)?a:RN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(dM,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zM,"metabase/mbql/schema.cljc",14,$CLJS.zF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zF,$CLJS.jF,$CLJS.V($CLJS.tj,YQ),YP,$CLJS.V($CLJS.tj,YQ),BP,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,YQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:zM}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},
$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:PN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Dq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:QN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(TQ,new $CLJS.h(null,
1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",14,$CLJS.Jq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dT)?dT.H:null])):
null));return $CLJS.n(a)?a:ON}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(MQ,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XN,"metabase/mbql/schema.cljc",14,$CLJS.Fq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.jF,$CLJS.V($CLJS.tj,OM),YP,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:XN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(Qka,new $CLJS.h(null,
1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fM,"metabase/mbql/schema.cljc",19,$CLJS.LE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LE,$CLJS.jF,$CLJS.V($CLJS.tj,OM),$CLJS.ui,$CLJS.V($CLJS.tj,OM),$CLJS.Gj,$CLJS.V($CLJS.tj,OM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:fM}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.kd(Ska,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cQ,"metabase/mbql/schema.cljc",23,$CLJS.aF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aF,$CLJS.jF,$CLJS.V($CLJS.tj,XP),yM,$CLJS.V($CLJS.tj,XP),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,sP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:cQ}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},
$CLJS.kd(Tja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",21,$CLJS.uF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uF,$CLJS.jF,$CLJS.V($CLJS.tj,XP),yM,$CLJS.V($CLJS.tj,XP),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,sP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:DM}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(fka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PM,"metabase/mbql/schema.cljc",20,$CLJS.iF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iF,$CLJS.jF,$CLJS.V($CLJS.tj,XP),yM,$CLJS.V($CLJS.tj,XP),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,sP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:PM}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==
typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tL,"metabase/mbql/schema.cljc",36,$CLJS.bF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.bF,$CLJS.jF,$CLJS.V($CLJS.tj,XP),yM,$CLJS.V($CLJS.tj,XP),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,sP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:tL}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),
$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",26,$CLJS.RE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.RE,Bja,$CLJS.V($CLJS.tj,OM),Ola,$CLJS.V($CLJS.tj,OM),zla,$CLJS.V($CLJS.tj,OM),Eja,$CLJS.V($CLJS.tj,OM),tla,$CLJS.V($CLJS.tj,OM),Aja,$CLJS.V($CLJS.tj,
OM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:aQ}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",28,$CLJS.ME,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.jF,$CLJS.V($CLJS.tj,HM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:wO}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&
"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",29,$CLJS.TE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,$CLJS.jF,$CLJS.V($CLJS.tj,HM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:jP}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VM,"metabase/mbql/schema.cljc",27,$CLJS.KE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.jF,$CLJS.V($CLJS.tj,HM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:VM}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&
"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(ska,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kM,"metabase/mbql/schema.cljc",28,$CLJS.WE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.jF,$CLJS.V($CLJS.tj,HM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:kM}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.jF,$CLJS.V($CLJS.tj,HM),$CLJS.Oj,$CLJS.V($CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.ji,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null)),$CLJS.ei,$CLJS.V($CLJS.tj,VN),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:IP}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KQ,"metabase/mbql/schema.cljc",27,$CLJS.HE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,Ija,$CLJS.V($CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,jla,XL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:KQ}(),rT],null)]))],null)],null));
$CLJS.Y(vP,eL($CLJS.SE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.ts,":case subclause"],null),$CLJS.TS,dS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),dS],null)],null)],null)])));
var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vP],null);
$CLJS.Y(bQ,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.kd(TO,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.Mq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mq,$CLJS.yD,$CLJS.V($CLJS.tj,XO),tQ,$CLJS.V($CLJS.tj,XO),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,XO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:HN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},
$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",51,$CLJS.Zr,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Zr,$CLJS.yD,$CLJS.V($CLJS.tj,SP),
tQ,$CLJS.V($CLJS.tj,XO),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,XO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:IN}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL?new $CLJS.Cc(function(){return tS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,kO,$CLJS.Di,$CLJS.Wi,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.DF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DF,$CLJS.yD,$CLJS.V($CLJS.tj,SP),tQ,$CLJS.V($CLJS.tj,SP),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,SP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GN,"metabase/mbql/schema.cljc",51,$CLJS.Nq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Nq,$CLJS.yD,$CLJS.V($CLJS.tj,SP),tQ,$CLJS.V($CLJS.tj,SP),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,SP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:GN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},
$CLJS.kd(FP,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,CQ,$CLJS.V($CLJS.tj,yL),BQ,$CLJS.V($CLJS.tj,
yL),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,yL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:UP}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,
$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:mP}(),hS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hO,"metabase/mbql/schema.cljc",55,$CLJS.hF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:hO}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),
$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WL,"metabase/mbql/schema.cljc",54,$CLJS.EE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(wS)?
wS.H:null])):null));return $CLJS.n(a)?a:WL}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",55,$CLJS.oF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:YN}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&
"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",53,$CLJS.JE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.JE,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:AO}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,
gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",69,$CLJS.GE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GE,$CLJS.yD,$CLJS.V($CLJS.tj,SP),tQ,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:lQ}(),zS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(vka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CN,"metabase/mbql/schema.cljc",68,$CLJS.XE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:CN}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),
$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",67,$CLJS.AE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AE,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(BS)?
BS.H:null])):null));return $CLJS.n(a)?a:$N}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(Hja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",67,$CLJS.FF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.yD,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:VQ}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&
"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(JL,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.tj,aN),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,KO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:rO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(Kja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.V($CLJS.tj,fR),Yja,$CLJS.V($CLJS.tj,fR),$CLJS.ei,$CLJS.V($CLJS.tj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:BO}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,
new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.$H,$CLJS.V($CLJS.tj,fR),$CLJS.ei,$CLJS.V($CLJS.tj,Dla),$CLJS.ki,$CLJS.V($CLJS.tj,$CLJS.V(iQ,nR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:UO}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(oka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sM,"metabase/mbql/schema.cljc",71,$CLJS.VH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VH,$CLJS.DO,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:sM}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.Aj,!0],
null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",74,$CLJS.kI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kI,$CLJS.DO,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:SO}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",72,$CLJS.OH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OH,$CLJS.DO,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:oP}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==
typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.DO,$CLJS.V($CLJS.tj,fR),$CLJS.ki,$CLJS.V($CLJS.tj,$CLJS.V(iQ,nR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:nP}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.Aj,
!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",70,$CLJS.dI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dI,$CLJS.DO,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:gP}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.kd(Eka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",78,$CLJS.gI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.DO,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:YO}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==
typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.kd(Cka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zL,"metabase/mbql/schema.cljc",
71,$CLJS.iI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.$H,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:zL}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.kd(kla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",73,$CLJS.fI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fI,$CLJS.$H,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(MS)?MS.H:null])):null));
return $CLJS.n(a)?a:ZQ}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,
[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",73,$CLJS.UH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UH,$CLJS.$H,$CLJS.V($CLJS.tj,fR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:tO}(),NS],null)])));
$CLJS.Y(OL,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wP,"metabase/mbql/schema.cljc",59,$CLJS.BE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BE,$CLJS.lx,$CLJS.V($CLJS.tj,XP),$CLJS.vu,$CLJS.V($CLJS.tj,$ja),$CLJS.Pw,$CLJS.V($CLJS.tj,$CLJS.V(iQ,SP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:wP}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},
$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",54,$CLJS.yF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:TP}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",55,$CLJS.AF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:CP}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&
"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",
55,$CLJS.HF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:FO}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",57,$CLJS.GF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GF,$CLJS.lx,$CLJS.V($CLJS.tj,XP),Fja,$CLJS.V($CLJS.tj,$CLJS.zj),$CLJS.iz,$CLJS.V($CLJS.tj,$CLJS.zj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:EQ}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,
new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",55,$CLJS.ps,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:iR}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==
typeof jL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",55,$CLJS.js,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.lx,$CLJS.V($CLJS.tj,XP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:fQ}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd(Uka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,
$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",56,$CLJS.IE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IE,CQ,$CLJS.V($CLJS.tj,XP),BQ,$CLJS.V($CLJS.tj,XP),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,XP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:tN}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aP,"null",$CLJS.nj,"null"],
null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qM,"metabase/mbql/schema.cljc",74,$CLJS.lF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lF,$CLJS.lx,$CLJS.V($CLJS.tj,XP),$CLJS.hz,$CLJS.V($CLJS.tj,$CLJS.zj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:qM}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==
typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},$CLJS.kd(FP,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aP,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
UP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,CQ,$CLJS.V($CLJS.tj,yL),BQ,$CLJS.V($CLJS.tj,yL),LM,$CLJS.V($CLJS.tj,$CLJS.V($CLJS.xi,yL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:UP}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},
$CLJS.kd(JL,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.tj,aN),
$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,KO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:rO}(),sT],null)])));
$CLJS.tT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,":field or :expression reference or expression",$CLJS.zi,function(a){return $CLJS.n(dL(TR,a))?gR:$CLJS.n(dL(QR,a))?$CLJS.zj:$CLJS.n(dL(UR,a))?$CLJS.ur:$CLJS.n(dL(WR,a))?$CLJS.$H:$CLJS.n(dL($CLJS.SE,a))?$CLJS.SE:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[gR,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,RR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$H,$CLJS.$R],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.NR],null)],null);$CLJS.Y(NQ,eL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,$CLJS.NR],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);$CLJS.Y(CO,eL($CLJS.kF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ar,$CLJS.NR],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CO],null);$CLJS.Y(ML,eL($CLJS.dF,$CLJS.H(["field-or-expression",$CLJS.tT])));
var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null);$CLJS.Y(FQ,eL($CLJS.EF,$CLJS.H(["field-or-expression",$CLJS.tT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.Y(lN,eL($CLJS.OE,$CLJS.H(["field-or-expression",$CLJS.tT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);$CLJS.Y(VP,eL($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.tT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null);$CLJS.Y(gN,eL($CLJS.ui,$CLJS.H(["field-or-expression",$CLJS.tT])));
var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);$CLJS.Y(TM,eL($CLJS.Gj,$CLJS.H(["field-or-expression",$CLJS.tT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.Y(NM,eL($CLJS.CF,$CLJS.H(["field-or-expression",$CLJS.tT,"pred",$CLJS.TS])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);$CLJS.Y(dN,eL($CLJS.sF,$CLJS.H(["pred",$CLJS.TS])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);$CLJS.Y(MP,eL($CLJS.qF,$CLJS.H(["pred",$CLJS.TS])));
var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.Y(jM,eL($CLJS.xF,$CLJS.H(["field-or-expression",$CLJS.tT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);$CLJS.Y(lL,eL($CLJS.PE,$CLJS.H(["field-or-expression",$CLJS.tT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null);$CLJS.Y(pN,eL($CLJS.wF,$CLJS.H(["field-or-expression",$CLJS.tT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.Y(cO,eL($CLJS.VE,$CLJS.H(["field-or-expression",$CLJS.tT,"percentile",bS])));
var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);$CLJS.Y(PP,eL($CLJS.eF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null),oR],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);
$CLJS.Y(LP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"unnamed aggregation clause or numeric expression",$CLJS.zi,function(a){return $CLJS.n(dL(TR,a))?fO:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[fO,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(Zka,
new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",60,$CLJS.dF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:oO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,
new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",64,$CLJS.EF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:sQ}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==
typeof jL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",65,$CLJS.OE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:sO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,
$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tF,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GL,"metabase/mbql/schema.cljc",76,$CLJS.xF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:GL}(),FT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bM,"metabase/mbql/schema.cljc",60,$CLJS.vF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:bM}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(Cja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,
$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mr,"metabase/mbql/schema.cljc",60,$CLJS.ui,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ui,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(AT)?AT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Mr}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(xja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qr,"metabase/mbql/schema.cljc",60,$CLJS.Gj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Qr}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&
"undefined"!==typeof JT?new $CLJS.Cc(function(){return JT},$CLJS.kd(Zja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sN,"metabase/mbql/schema.cljc",18,$CLJS.eF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,lka,$CLJS.V($CLJS.tj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.gr,pka,XL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:sN}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(xka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,
$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kN,"metabase/mbql/schema.cljc",62,$CLJS.qF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,$CLJS.yn,$CLJS.V($CLJS.tj,aO)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:kN}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YL,"metabase/mbql/schema.cljc",68,$CLJS.sF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.yn,$CLJS.V($CLJS.tj,aO)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:YL}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(Ila,new $CLJS.h(null,1,[$CLJS.Aj,!0],
null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",66,$CLJS.CF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.CF,gQ,$CLJS.V($CLJS.tj,ZP),$CLJS.yn,$CLJS.V($CLJS.tj,aO)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:pO}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(JL,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,
new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.tj,aN),$CLJS.Qi,$CLJS.V($CLJS.tj,$CLJS.V(iQ,KO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:rO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&
"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",
68,$CLJS.wF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:qQ}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.kd(Jka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,
kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",72,$CLJS.VE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VE,gQ,$CLJS.V($CLJS.tj,ZP),$CLJS.VE,$CLJS.V($CLJS.tj,SP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(IT)?IT.H:null])):
null));return $CLJS.n(a)?a:OP}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.kd(Nja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BL,"metabase/mbql/schema.cljc",77,$CLJS.PE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,gQ,$CLJS.V($CLJS.tj,ZP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:BL}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,
$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",74,$CLJS.kF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.jF,$CLJS.V($CLJS.tj,$CLJS.V(iQ,HM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:bO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.kd(aka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([iO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,kQ,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),
$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ii,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.jF,$CLJS.V($CLJS.tj,$CLJS.V(iQ,HM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:$CLJS.ii}(),uT],null)]))],null)],null));
$CLJS.Y(dP,eL($CLJS.wL,$CLJS.H(["aggregation",LP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.ts,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null)],null)])));
$CLJS.Y(hQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,2,[$CLJS.ts,"aggregation clause or numeric expression",$CLJS.zi,function(a){return $CLJS.n(dL($CLJS.wL,a))?$CLJS.wL:$M}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$M,LP],null)],null));$CLJS.Y(FL,eL($CLJS.gF,$CLJS.H(["field",PR])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null);$CLJS.Y(eO,eL($CLJS.$E,$CLJS.H(["field",PR])));
LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);
$la=fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof KT?new $CLJS.Cc(function(){return KT},$CLJS.kd(rla,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
LN,"metabase/mbql/schema.cljc",15,$CLJS.gF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,$CLJS.jF,$CLJS.V($CLJS.tj,AQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:LN}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof LT?new $CLJS.Cc(function(){return LT},$CLJS.kd(jka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,
$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",16,$CLJS.$E,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.jF,$CLJS.V($CLJS.tj,AQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:GP}(),LT],null)]));
MT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,$CLJS.IL,$CLJS.xM,$CLJS.CE,$CLJS.Ps,$CLJS.Mj,$CLJS.DO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,MT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.IL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VO,rR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),rR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,MT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.xM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,rR],null)],null)],null);
NT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,MT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Zh],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,NT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,MR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ii,$CLJS.Zh],
null)],null)],null)],null)],null);$CLJS.OT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.DO,null,$CLJS.Ps,null,$CLJS.ur,null,$CLJS.Mj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.OT);
$CLJS.Y(zP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Vi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,NT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,ema],null)],null)],null)],null)],null));
var PT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,oR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,oR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,PT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,$CLJS.Zh],null)],null)],null),QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null),RT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.zi,function(a){return $CLJS.n($CLJS.dD($CLJS.sd,
$CLJS.kP)(a))?$CLJS.kP:$CLJS.jD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,PT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kP,$CLJS.Zh],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,QT],null)],null),ST=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cC,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,oR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.FN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Fj],null)],null)],null),TT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.ts,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.gE,null,$CLJS.QD,null,$CLJS.$D,null,$CLJS.dE,null],null),null)),UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);
$CLJS.Y(jN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RQ,$CLJS.TS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.wx,$CLJS.cz],null),UT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TN,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,ST],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.gO,$CLJS.JP),$CLJS.Oe($CLJS.dD($CLJS.gO,$CLJS.JP)))],null)],null));var hma=$CLJS.Q,VT;var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null)],null);
if($CLJS.rd(WT)&&$CLJS.E.g($CLJS.z(WT),$CLJS.ej)){var XT=$CLJS.y(WT);$CLJS.z(XT);var YT=$CLJS.B(XT),ima=$CLJS.sd($CLJS.z(YT))?YT:$CLJS.ae(null,YT),ZT=$CLJS.y(ima),jma=$CLJS.z(ZT),kma=$CLJS.B(ZT);VT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.R.j(jma,$CLJS.ui,1)],null),kma)}else VT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,WT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(lM,new $CLJS.P(null,3,5,hma,[$CLJS.nr,VT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"All join aliases must be unique."],null),function(a){return $CLJS.QK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.VD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);
$CLJS.Y(pQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.h(null,1,[$CLJS.ts,"Distinct, non-empty sequence of Field clauses"],null),gL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),$CLJS.NR],null))],null));
$CLJS.Y(hP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),RT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),$CLJS.aS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,new $CLJS.h(null,1,
[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),$CLJS.NR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,oR,$CLJS.tT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),UT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.TS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lR,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),sR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),gL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[lP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[lP,rR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,rR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,ST],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Fl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JP,$CLJS.gO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,
new $CLJS.h(null,1,[$CLJS.ts,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.YE);b=$CLJS.J.g(b,$CLJS.RD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.$T=$CLJS.zg([$CLJS.BF,$CLJS.DO,fN,RM,UN,cR,AN,$CLJS.bR,$CLJS.Ps,zN,PL,WP,SQ,$CLJS.eM,OQ,cN,$CLJS.ZN,xN,$CLJS.Ki,$CLJS.wN,xL,$CLJS.BM,$CLJS.vN,yO,$CLJS.ur,$CLJS.Mj,rN],[new $CLJS.h(null,1,[KN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.DO,null,$CLJS.Ps,null,$CLJS.ur,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.DO,null,$CLJS.bR,null,$CLJS.ZN,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,eQ,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[fN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,gR,$CLJS.sD,eQ,KN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,RM,null,$CLJS.Ps,null,$CLJS.Ki,null,xL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[UN,null],null),null)],null),new $CLJS.h(null,1,[KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[cR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,
gR,$CLJS.sD,hla,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[AN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,gR,KN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,RM,null,$CLJS.Ps,null,$CLJS.Ki,null,xL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,gR,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[zN,null],null),null)],null),new $CLJS.h(null,1,[KN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[PL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,eQ,KN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.BF,null,cR,null,PL,null,WP,null,xN,null,$CLJS.Ki,null,xL,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[SQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bR,null,$CLJS.eM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,$CLJS.zj,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[cN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.DO,null,$CLJS.bR,null,$CLJS.ZN,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,1,[KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[xN,null],null),null)],null),new $CLJS.h(null,1,[KN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bR,null,$CLJS.wN,null],null),null)],null),new $CLJS.h(null,1,[KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[xL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bR,null,$CLJS.BM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.DO,KN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bR,
null,$CLJS.vN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,gR,$CLJS.sD,eQ,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[yO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.ur,KN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.BF,null,$CLJS.Ki,null,$CLJS.ur,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Vi,$CLJS.zj,KN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.BF,null,cR,null,PL,null,WP,null,xN,null,$CLJS.Ki,null,xL,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Vi,
gR,$CLJS.sD,TL,KN,new $CLJS.Rg(null,new $CLJS.h(null,1,[rN,null],null),null)],null)]);$CLJS.Y(rQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.$T)));$CLJS.Y(aM,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.$T)));
$CLJS.Y(EP,eL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,oR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,oR],null)],null)],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EP],null);$CLJS.Y(AL,eL($CLJS.CE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.NR,aU],null)])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AL],null);$CLJS.Y(vM,eL(uN,$CLJS.H(["target",aU])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.NR,fL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof bU?new $CLJS.Cc(function(){return bU},$CLJS.kd(Hka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,
1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",21,$CLJS.CE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.nI,$CLJS.V($CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,HM,JN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:pP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kO.h($CLJS.ld("undefined"!==typeof $CLJS.hL&&
"undefined"!==typeof iL&&"undefined"!==typeof jL&&"undefined"!==typeof cU?new $CLJS.Cc(function(){return cU},$CLJS.kd(Wka,new $CLJS.h(null,1,[$CLJS.Aj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.gi,kO,$CLJS.Di,$CLJS.Wi,$CLJS.Vj,gM,$CLJS.fi,$CLJS.Hj,$CLJS.Kj],[$CLJS.kd(QP,new $CLJS.h(null,1,[$CLJS.Hj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",20,uN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[uN,
$CLJS.nI,$CLJS.V($CLJS.tj,JN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:hR}(),cU],null)]))],null),aL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hM],null);
$CLJS.Y(hM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.kP,$CLJS.FE),$CLJS.Oe($CLJS.dD($CLJS.kP,$CLJS.FE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.kP);var d=$CLJS.J.g(c,$CLJS.FE);c=$CLJS.J.g(c,$CLJS.Vi);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.XJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.FE,$CLJS.kP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kP,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Zh],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),
oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Zh],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Zh],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),BR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[qO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),sR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$L,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,qO);b=$CLJS.J.g(b,$L);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.ur],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.dr,$CLJS.ij,Tka,$CLJS.nN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,oR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Zh,$CLJS.Zh],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qN,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Zh,$CLJS.Zh],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Zh],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.TN)],null)],null));var dU=$CLJS.$K(aL);(function(){var a=pja();return function(b){if($CLJS.n(dU.h?dU.h(b):dU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.XK(b);throw $CLJS.Uh($CLJS.fD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();